import React, { useEffect, useState } from 'react';
import { Modal } from 'bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { find, isEmpty, filter } from 'lodash';
import Big from 'big.js';

import CloseModalIcon from 'images/cerrar.svg';
import BackIcon from 'images/Arrow-ios-back-fill.svg';

import CatalogProductQuantity from '../../CatalogProductQuantity';
import ProductImagesCarousel from './ProductImagesCarousel';
import VariantsComponent from './VariantsComponent';

import { addLine, removeLine, updateLine } from '../../../../actions/cart';
import { formatMoney } from '../../../../utils/number';
import { validateAvailable } from '../../../../utils/productUtils';

const ProductModal = ({ product, onDismiss, isSmallDevice }) => {
  const { title, description = "", image_urls = [] } = product;

  const currentLine = useSelector((reduxState) => {
    const { orderLines = [] } = reduxState.cartReducer;
    return orderLines.find((line) => line.product.web_id === product.web_id);
  });
  const { retailer } = useSelector((reduxState) => reduxState.retailerReducer);

  const [subtotal, setSubtotal] = useState(price);
  const [quantity, setQuantity] = useState(1);
  const [variants, setVariants] = useState([]);
  const [selectedVariants, setSelectedVariants] = useState({});
  const [openedMobilModalOptions, setOpenedMobilModalOptions] = useState(false);
  const [price, setPrice] = useState(0);
  const [images, setImages] = useState(image_urls);
  const [selectedProduct, setSelectedProduct] = useState({});

  const hasCombinations = !isEmpty(product.product_variant_combinations);

  const dispatch = useDispatch();

  useEffect(() => {
    setSubtotal(Number(Big(Number(price)).times(Big(Number(quantity)))));
  }, [quantity, price]);

  useEffect(() => {
    setImages([]);
    if (product.web_id) {
      buildVariants();
      const el = document.getElementById("productModal");
      if (el) {
        const pModal = new Modal(el);
        pModal.show();
        el.addEventListener("hidden.bs.modal", onDismiss);
        el.addEventListener("hide.bs.modal", onDismiss);

        return () => el.removeEventListener("hidden.bs.modal", onDismiss);
      }
    } else {
      setQuantity(1);
    }
    return () => { };
  }, [product.web_id]);

  useEffect(() => {
    if (isEmpty(selectedProduct)) {
      setPrice(0);
      setImages(image_urls);
      return;
    };

    setPrice(Number(selectedProduct.price));

    if (selectedProduct.is_combination && !isEmpty(selectedProduct.attached_image)) {
      setImages([selectedProduct.attached_image]);
      return;
    };

    setImages(image_urls);
  }, [selectedProduct]);

  const canBeSelectedProduct = () => {
    if (hasCombinations) {
      if (!selectedProduct) return false;

      return validateAvailable(selectedProduct);
    };

    return validateAvailable(product);
  };

  const buildVariants = () => {
    setVariants(
      product.product_variant_combinations.map((variant) => ({
        ...variant,
        selected: false,
      }))
    );
  };

  const addProductToCart = (e, close = false) => {
    e.preventDefault();

    if (isEmpty(selectedProduct)) return;

    dispatch(addLine(selectedProduct, quantity, retailer.tax_amount));

    if (close) {
      const el = document.getElementById("productModal");
      if (el) {
        const pModal = Modal.getInstance(el);
        pModal.hide();
      }
    } else {
      window.location.href = "/checkout";
    }
  };

  const onChangeQuantity = (newQuantity) => {
    if (newQuantity) {
      setQuantity(newQuantity);
    } else {
      setQuantity(1);
      if (currentLine) {
        dispatch(removeLine(currentLine.web_id));
      }
    }
  };

  const onClickAddAndPay = (e) => {
    addProductToCart(e, isSmallDevice);
  };

  const handleVariantOptionSelect = (option) => {
    setSelectedVariant(option);
    const variant = find(product.product_variant_combinations, {
      id: option?.value,
    });
    if (variant) {
      setPrice(variant.price);
    }
  };

  const handleSelectedVariant = (e, variant) => {
    const checked = e.target.checked;

    if (checked && !find(selectedVariants, { web_id: variant.web_id })) {
      setSelectedVariants([...selectedVariants, variant]);
    } else {
      setSelectedVariants(
        filter(selectedVariants, (o) => o.web_id != variant.web_id)
      );
    }

    setVariants(
      variants.map((comb) => {
        if (comb.web_id == variant.web_id) {
          return { ...comb, selected: checked };
        }

        return comb;
      })
    );
  };

  return (
    <div className="modal product-modal" id="productModal">
      <div className="modal-dialog modal-dialog-centered modal-xl modal-fullscreen-md-down">
        <div className="modal-content catalog-product-modal">
          <button
            type="button"
            className="btn btn-close-modal"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <img src={CloseModalIcon} alt="" />
          </button>
          <div
            className="row ps-md-3 pe-md-3 m-0 product-modal-content-row"
            style={{ minHeight: 400, zIndex: openedMobilModalOptions ? 10 : 0 }}
          >
            <div className="col-12 col-md-5 product-modal-image-container">
              <button
                type="button"
                className="btn d-md-none product-modal-back"
                style={{ zIndex: openedMobilModalOptions ? 0 : 10 }}
                onClick={onDismiss}
              >
                <img src={BackIcon} alt="" />
              </button>
              {product.web_id && (
                <ProductImagesCarousel
                  product={product}
                  images={images}
                  isSmallDevice={isSmallDevice}
                />
              )}
            </div>
            <div className="col-12 col-md-7 mt-md-4 border-start product-modal-container">
              <div className="pt-3 pt-md-2  ps-3 pe-3 product-modal-content">
                <h3 className="modal-title fw-bold border-bottom">{title}</h3>
                <h5 className="fw-bold pt-2 pb-4">
                  {retailer.currency}
                  {formatMoney(selectedProduct?.price || 0)}{" "}
                  {retailer.currency_code}
                </h5>
                <div id="product-description">
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                </div>

                {isSmallDevice && (
                  <div className="mt-4 mb-4 product-variant-container">
                    <div key={`variant-select`} className={`d-flex flex-row`}>
                      <div className="pt-2 pb-1 w-100">
                        <VariantsComponent
                          product={product}
                          setSelectedProduct={setSelectedProduct}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {!isSmallDevice && !isEmpty(product) && (
                  <VariantsComponent
                    product={product}
                    setSelectedProduct={setSelectedProduct}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="bg-white product-modal-footer d-flex">
            <div className="flex-grow-1 d-flex flex-column flex-sm-row align-items-center product-modal-subtotal">
              {quantity > 0 && (
                <>
                  Subtotal
                  <h5 className="ps-sm-3 fw-bold mb-0">
                    {retailer.currency}
                    {formatMoney(Number(subtotal))} {retailer.currency_code}
                  </h5>
                </>
              )}
            </div>
            {quantity > 0 && (
              <CatalogProductQuantity
                quantity={quantity}
                onChangeQuantity={onChangeQuantity}
              />
            )}

            {canBeSelectedProduct() && (
              <>
                <button
                  type="button"
                  className="btn text-muted d-flex flex-row text-white me-md-1 s-btn-secondary"
                  onClick={onClickAddAndPay}
                  disabled={isEmpty(selectedProduct)}
                >
                  Agregar
                  {!isSmallDevice && " y pagar"}
                </button>
                <button
                  type="button"
                  className="btn btn-main text-white d-none d-md-block"
                  onClick={(e) => addProductToCart(e, true)}
                  disabled={isEmpty(selectedProduct)}
                >
                  Agregar y seguir comprando
                </button>
              </>
            )}

            {!canBeSelectedProduct() && (
              <>
                <button
                  type="button"
                  className="btn text-muted me-md-1 sold-out-btn"
                >
                  Agotado
                </button>
                <button
                  type="button"
                  className="btn text-muted me-md-1 sold-out-btn solid"
                >
                  Agotado
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
